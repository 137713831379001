import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import UserGuestGuard, { LinguistGuestGuard, ClientGuestGuard } from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import useAuth from '../hooks/useAuth';
// import TypeBasedGuard from '../guards/TypeBasedGuard';
// config
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGIN_LINGUIST } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import PrivacyPolicy from 'src/pages/linguist/PrivacyPolicy';
import BarzanoReport from 'src/pages/dashboard/BarzanoReport';
import PreBookingResponse from 'src/pages/linguist/PreBookingResponse';
import BookingResponse from 'src/pages/linguist/BookingResponse';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  return useRoutes([
    {
      path: 'intranet',
      children: [
        {
          index: true,
          element: (
            <UserGuestGuard userType={'user'}>
              <Login />
            </UserGuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <UserGuestGuard userType={'user'}>
              <Register />
            </UserGuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
      ],
    },
    // LINGUIST
    {
      path: '/vendors',
      children: [
        {
          index: true,
          element: (
            <LinguistGuestGuard userType={'Linguist'}>
              <LinguistLogin />
            </LinguistGuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <LinguistGuestGuard userType={'Linguist'}>
              <LinguistRegister />
            </LinguistGuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LinguistLogin /> },
        { path: 'register-unprotected', element: <LinguistRegister /> },
        { path: 'reset-password', element: <LinguistResetPassword /> },
        { path: 'new-password', element: <LinguistNewPassword /> },
      ],
    },
    // Client
    {
      path: 'clients',
      children: [
        {
          index: true,
          element: (
            <ClientGuestGuard userType={'Client'}>
              <ClientLogin />
            </ClientGuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <ClientGuestGuard userType={'Client'}>
              <ClientRegister />
            </ClientGuestGuard>
          ),
        },
        // { path: 'login', element: <ClientLogin /> },
        { path: 'register', element: <ClientRegister /> },
        { path: 'reset-password', element: <ClientResetPassword /> },
        { path: 'new-password', element: <ClientNewPassword /> },
      ],
    },

    // Dashboard Project manager Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard userType={['user']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app',
          element: (
            <AuthGuard userType={['user']}>
              {user?.role === 'Project Manager' ? <GeneralProjectApp /> : <GeneralQuoteApp />}
            </AuthGuard>
          ),
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'cards', element: <UserCards /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserEdit /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'report/:id', element: <AMReport /> },
            { path: 'report/quote/:id', element: <AMReport type={'quote'} /> },
            { path: 'report/clients/:id', element: <AMReport type={'clients'} /> },
            { path: 'report/calls/:id', element: <AMReport type={'calls'} /> },
          ],
        },

        {
          path: 'task',
          children: [
            { element: <Navigate to="/dashboard/task/list" replace />, index: true },
            { path: 'list/:dept', element: <TaskList /> },
            { path: 'list', element: <TasksDepartmentsList /> },
            { path: 'new', element: <TaskCreate /> },
            { path: ':id/edit', element: <TaskEdit /> },
          ],
        },

        {
          path: 'vendor',
          children: [
            { element: <Navigate to="/dashboard/vendor/translator" replace />, index: true },
            { path: 'translator', element: <LinguistTranslatorList /> },
            { path: 'interpreter', element: <LinguistInterpreterList /> },
            { path: 'incomplete', element: <LinguistIncompleteList /> },
            { path: 'technician', element: <LinguistTechnicianList /> },
            { path: 'blacklist-vendors', element: <LinguistBlackList /> },
            { path: ':id', element: <LinguistDetail /> },
            { path: ':id/cv', element: <LinguistCVDetail /> },
            { path: 'technician/:id', element: <TechnicianView /> },
          ],
        },

        {
          path: 'TwilioCalls',
          children: [
            { element: <Navigate to="/dashboard/TwilioCalls/call" replace />, index: true },
            { path: 'call', element: <TwilioCall /> },
          ],
        },
        // {
        //   path: 'TextToSpeech',
        //   children: [
        //     { element: <Navigate to="/dashboard/TextToSpeech/create" replace />, index: true },
        //     { path: 'create', element: <TextToSpeech /> },
        //   ],
        // },
        
        {
          path: 'barzanoProject',
          children: [
            { element: <Navigate to="/dashboard/barzanoProject/list" replace />, index: true },
            { path: 'list', element: <BarzanoProjectList /> },
            { path: 'deliverylist', element: <BarzanoDeliveryProjectList /> },
            { path: 'new', element: <BarzanoProjectCreate /> },
            { path: ':id', element: <BarzanoProjectDetails /> },
            { path: ':id/booking', element: <BarzanoProjectBooking /> },
            { path: ':id/edit', element: <BarzanoProjectEdit /> },
            { path: 'ocr', element: <OcrReport /> },
          ],
        },
        {
          path: 'translationProject',
          children: [
            { element: <Navigate to="/dashboard/translationProject/list" replace />, index: true },
            { path: 'list', element: <TranslationProjectList /> },
            { path: 'new', element: <TranslationProjectCreate /> },
            { path: ':id', element: <TranslationProjectDetails /> },
            { path: ':id/booking', element: <TranslationProjectBooking /> },
            { path: ':id/edit', element: <TranslationProjectEdit /> },
          ],
        },
        {
          path: 'company',
          children: [
            { element: <Navigate to="/dashboard/company/list" replace />, index: true },
            { path: 'list', element: <CompanyList /> },
            { path: 'new', element: <CompanyCreate /> },
            { path: ':id', element: <CompanyDetails /> },
            { path: ':id/edit', element: <CompanyEdit /> },
          ],
        },
        {
          path: 'interpretationProject',
          children: [
            { element: <Navigate to="/dashboard/interpretationProject/list" replace />, index: true },
            { path: 'list', element: <InterpretationProjectList /> },
            { path: 'new', element: <InterpretationProjectCreate /> },
            { path: ':id', element: <InterpretationProjectDetails /> },
            { path: ':id/booking', element: <InterpretationProjectBooking /> },
            { path: ':id/edit', element: <InterpretationProjectEdit /> },
          ],
        },
        {
          path: 'videoConferenceProject',
          children: [
            { element: <Navigate to="/dashboard/videoConferenceProject/list" replace />, index: true },
            { path: 'list', element: <VideoConferenceProjectList /> },
            { path: 'new', element: <VideoConferenceProjectCreate /> },
            { path: ':id', element: <VideoConferenceProjectDetails /> },
            { path: ':id/edit', element: <VideoConferenceProjectEdit /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'projects/calendar', element: <Calendar /> },
        { path: 'vault', element: <Vault /> },
        { path: 'vault/:id', element: <VaultFolder /> },
        { path: 'vault/:id/:fileType', element: <VaultFiles /> },
        { path: 'create-post', element: <CreatePost /> },

        {
          path: 'issue',
          children: [
            { element: <Navigate to="/dashboard/issue/list" replace />, index: true },
            { path: 'list', element: <IssueList /> },
            { path: 'new', element: <IssueCreate /> },
            { path: ':id', element: <IssueDetails /> },
            { path: ':id/edit', element: <IssueEdit /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'barzanoClient/create', element: <BarzanoInvoiceCreate /> },
            { path: 'client/create', element: <LYCIClientInvoiceCreate /> },
            { path: 'client/:id/edit', element: <BarzanoInvoiceEdit /> },
            { path: 'create', element: <InvoiceCreate /> },
            // { path: 'client/:id/edit', element: <InvoiceEdit /> },
            { path: 'client/:id', element: <BarzanoInvoiceView /> },
            { path: 'client/list', element: <BarzanoInvoiceList /> },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/editFZ', element: <IssueEdit /> },
          ],
        },
        {
          path: 'reports',
          children: [
            { element: <Navigate to="/dashboard/reports/quote" replace />, index: true },
            { path: 'quote', element: <QuotesConversionReports /> },
            { path: 'marketingChannelList', element: <MarketingChannelQuotesList /> },
            { path: 'calls', element: <CallRecordsList /> },
            { path: 'issue', element: <IssueReport /> },
            { path: 'totalsales', element: <TotalSalesFlowChartReport /> },
          ],
        },
        { path: 'management', element: <Management /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    // Dashboard Account Manager Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard userType={['user']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app',
          element: (
            <AuthGuard userType={['user']}>
              {user?.role === 'Project Manager' ? <GeneralProjectApp /> : <GeneralQuoteApp />}
            </AuthGuard>
          ),
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'cards', element: <UserCards /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserEdit /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },

        {
          path: 'task',
          children: [
            { element: <Navigate to="/dashboard/task/list" replace />, index: true },
            { path: 'list/:dept', element: <TaskList /> },
            { path: 'list', element: <TasksDepartmentsList /> },
            { path: 'new', element: <TaskCreate /> },
            { path: ':id/edit', element: <TaskEdit /> },
          ],
        },

        {
          path: 'linguist',
          children: [
            { element: <Navigate to="/dashboard/linguist/list" replace />, index: true },
            // { path: 'list', element: <LinguitList /> },
          ],
        },

        {
          path: 'TwilioCalls',
          children: [
            { element: <Navigate to="/dashboard/TwilioCalls/call" replace />, index: true },
            { path: 'call', element: <TwilioCall /> },
          ],
        },
        // {
        //   path: 'TextToSpeech',
        //   children: [
        //     { element: <Navigate to="/dashboard/TextToSpeech/create" replace />, index: true },
        //     { path: 'create', element: <TextToSpeech /> },
        //   ],
        // },
        {
          path: 'translationQuote',
          children: [
            { element: <Navigate to="/dashboard/translationQuote/list" replace />, index: true },
            { path: 'list', element: <TranslationQuoteList /> },
            { path: 'new', element: <TranslationQuoteCreate /> },
            { path: ':id', element: <TranslationQuoteDetails /> },
            { path: ':id/edit', element: <TranslationQuoteEdit /> },
          ],
        },
        {
          path: 'company',
          children: [
            { element: <Navigate to="/dashboard/company/list" replace />, index: true },
            { path: 'list', element: <CompanyList /> },
            { path: 'new', element: <CompanyCreate /> },
            { path: ':id', element: <CompanyDetails /> },
            { path: ':id/edit', element: <CompanyEdit /> },
          ],
        },
        {
          path: 'interpretationQuote',
          children: [
            { element: <Navigate to="/dashboard/interpretationQuote/list" replace />, index: true },
            { path: 'list', element: <InterpretationQuoteList /> },
            { path: 'new', element: <InterpretationQuoteCreate /> },
            { path: ':id', element: <InterpretationQuoteDetails /> },
            { path: ':id/edit', element: <InterpretationQuoteEdit /> },
          ],
        },
        {
          path: 'videoConferenceProject',
          children: [
            { element: <Navigate to="/dashboard/videoConferenceProject/list" replace />, index: true },
            { path: 'list', element: <VideoConferenceProjectList /> },
            { path: 'new', element: <VideoConferenceProjectCreate /> },
            { path: ':id', element: <VideoConferenceProjectDetails /> },
            { path: ':id/edit', element: <VideoConferenceProjectEdit /> },
          ],
        },

        {
          path: 'reports',
          children: [
            { element: <Navigate to="/dashboard/reports/quote" replace />, index: true },
            { path: 'quote', element: <QuotesConversionReports /> },
            { path: 'marketingChannelList', element: <MarketingChannelQuotesList /> },
            { path: 'calls', element: <CallRecordsList /> },
            { path: 'issue', element: <IssueReport /> },
            { path: 'allProjectsList', element: <AllGeneralProjectsList /> },
            { path: 'barzano', element: <BarzanoReport /> },
            { path: 'revenueAnalysis', element: <TotalSalesFlowChartReport /> },
            { path: 'expenseReport', element: <ExpenseReport /> },
          ],
        },
        {
          path: 'brochure',
          children: [
            { element: <Navigate to="/dashboard/brochure/list" replace />, index: true },
            { path: 'list', element: <BrochureList /> },
            { path: 'new', element: <BrochureCreate /> },
            { path: ':id/edit', element: <BrochureEdit /> },
            { path: ':id', element: <BrochureDetails /> },
          ],
        },
        {
          path: 'expense',
          children: [
            { element: <Navigate to="/dashboard/expense/list" replace />, index: true },
            { path: 'list', element: <ExpenseList /> },
            { path: 'new', element: <ExpenseCreate /> },
            { path: ':id/edit', element: <ExpenseEdit /> },
            { path: ':id', element: <ExpenseDetails /> },
          ],
        },
        {
          path: 'credit-note',
          children: [
            { element: <Navigate to="/dashboard/expense/list" replace />, index: true },
            { path: 'new', element: <CreditNoteCreate /> },
            { path: ':id/edit', element: <CreditNoteEdit /> },
            { path: ':id', element: <CreditNoteDetails /> },
          ],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/dashboard/inventory/list" replace />, index: true },
            { path: 'list', element: <InventoryList /> },
            { path: 'new', element: <InventoryCreate /> },
            { path: ':id/edit', element: <InventoryEdit /> },
            { path: ':id', element: <InventoryDetails /> },
            { path: 'overview', element: <InventoryOverview /> },
          ],
        },        
        {
          path: 'ticket',
          children: [
            { element: <Navigate to="/dashboard/ticket/list" replace />, index: true },
            { path: 'list', element: <TicketList /> },
            { path: 'new', element: <TicketCreate /> },
            { path: ':id/edit', element: <TicketEdit /> },
            { path: ':id', element: <TicketDetails /> },     
            { path: 'kanban', element: <TicketKanban /> },    
            { path: 'support', element: <TicketSupport /> },
          ],
        },

        { path: 'calendar', element: <Calendar /> },
        { path: 'projects/calendar', element: <Calendar /> },

        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    {
      path: 'vendors',
      element: (
        <AuthGuard userType={['Marketeer']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_LINGUIST} replace />, index: true },
        { path: 'marketingChannelList', element: <MarketingChannelList /> },
      ],
    },
    // Formatter
    {
      path: 'vendors',
      element: (
        <AuthGuard userType={['Formatter']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_LINGUIST} replace />, index: true },
        { path: 'formattingProject/:id', element: <FormattingFileDetails /> },
        { path: 'formattingProjectslist', element: <FormattingFilesList /> },
        { path: 'projectsCalender', element: <ProjectsCalendar /> },
      ],
    },
    // Technician
    {
      path: 'vendors',
      element: (
        <AuthGuard userType={['Technician']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_LINGUIST} replace />, index: true },
        { path: 'technicianSheets/:id', element: <TechnicianDetails /> },
        { path: 'technicianSheets', element: <TechnicianSheets /> },
        { path: 'inventory', element: <TechnicianInventoryList /> },
        { path: 'inventory/:id', element: <TechnicianInventoryDetails /> },
        { path: 'inventory/new', element: <TechnicianInventoryCreate /> },
        { path: 'inventory/:id/edit', element: <TechnicianInventoryEdit /> },
      ],
    },
    // Linguist
    {
      path: 'vendors',
      element: (
        <AuthGuard userType={['Linguist']}>
          <VendorProfileOnBoarding />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_LINGUIST} replace />, index: true },
        { path: 'invoices', element: <TechnicianInvoice /> },
        { path: 'invoices/:id', element: <TechnicianInvoiceDetail /> },
        { path: 'invoices/new', element: <TechnicianInvoiceCreate /> },
        {
          path: 'translationAllocationResponse',
          children: [
            { element: <Navigate to="/404" replace />, index: true },
            { path: ':id', element: <TranslationAllocationResponse /> },
          ],
        },
        {
          path: 'translationPRAllocationResponse',
          children: [
            { element: <Navigate to="/404" replace />, index: true },
            { path: ':id', element: <TranslationPRAllocationResponse /> },
          ],
        },
        {
          path: 'barzanoAllocationResponse',
          children: [
            { element: <Navigate to="/404" replace />, index: true },
            { path: ':id', element: <BarzanoAllocationResponse /> },
          ],
        },
        {
          path: 'interpretationAllocationResponse',
          children: [
            { element: <Navigate to="/404" replace />, index: true },
            { path: ':id', element: <InterpretationAllocationResponse /> },
          ],
        },
        
        { path: 'dashboard', element: <LinguistDashboard /> },
        { path: 'onBoardingSteps', element: <></> },
        { path: 'profile', element: <LinguistProfile /> },
        {path:'projects', element:<LinguistProjects/>},
        {path:'paymentInfo', element:<LinguistPaymentInfo/>},
        {
          path: 'services', element:<Services />
        },
        { path: 'invoice', element: <VendorInvoices /> },
        { path: 'invoice/:id', element: <VendorInvoiceDetail /> },
        { path: 'invoice/new', element: <VendorInvoiceCreate /> },
        { path: 'translationProjects/list', element: <TranslationProjects /> },
        { path: 'translationProjects/:allocationId', element: <ProjectView /> },
        { path: 'interpretationProjects/list', element: <InterpretationProjects /> },
        { path: 'interpretationProjects/:allocationId', element: <ProjectView /> },
        { path: 'proof-reading', element: <PRList /> },
        { path: 'proof-reading/:allocationId', element: <PRView /> },
        { path: 'verify-email', element: <VerifyEmail /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
        { path: 'change-password', element: <UpdatePassword /> },
        
        { path: 'invoice', element: <VendorInvoices /> },
        { path: 'invoice/:id', element: <VendorInvoiceDetail /> },
        { path: 'invoice/new', element: <VendorInvoiceCreate /> },
        { path: 'translationProjects/list', element: <TranslationProjects /> },
        { path: 'translationProjects/:allocationId', element: <ProjectView /> },
        { path: 'interpretationProjects/list', element: <InterpretationProjects /> },
        { path: 'interpretationProjects/:allocationId', element: <ProjectView /> },
        { path: 'proof-reading', element: <PRList /> },
        { path: 'proof-reading/:allocationId', element: <PRView /> },
        { path: 'verify-email', element: <VerifyEmail /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
        { path: 'change-password', element: <UpdatePassword /> },
        {
          path: 'pre-booking',
          children: [
            { element: <Navigate to="/404" replace />, index: true },
            { path: ':id', element: <PreBookingResponse /> },
          ],
        },
        {
          path: 'booking',
          children: [
            { element: <Navigate to="/404" replace />, index: true },
            { path: ':id', element: <BookingResponse /> },
          ],
        },
      ],
    },
    // {
    //   path: 'client',
    //   element: (
    //     <AuthGuard userType={['Client']}>
    //       <LogoOnlyLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     // { element: <Navigate to={PATH_AFTER_LOGIN_CLIENT} replace />, index: true },
    //     { path: 'barzanoCalendar', element: <BarzanoCalendar /> }
    //   ]
    // },
    // Client
    {
      path: 'clients',
      element: (
        <AuthGuard userType={['Client']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to={PATH_AFTER_LOGIN_CLIENT} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        // { path: 'barzanoCalendar', element: <BarzanoCalendar /> },
        { path: 'edit-profile', element: <EditClient /> },
        { path: 'instant-quote', element: <InstantQuote /> },
        { path: 'order', element: <OrderList /> },
        { path: 'order/:type/:id', element: <OrderDetails /> },
        { path: 'book/interpreter', element: <BookInterpreter /> },
        { path: 'interpreter-payment/:id', element: <InterpreterPayment /> },
        { path: 'interpreter-order/:id', element: <InterpreterOrderNow /> },
        { path: 'get-interpret-quote/:id', element: <InterpreterGetQuote /> },
        { path: 'book/web-conference', element: <BookWebConference /> },
        { path: 'webconference-payment/:id', element: <WebConferencePayment /> },
        { path: 'webconference-order/:id', element: <WebConferenceOrderNow /> },
        { path: 'webconference-quote/:id', element: <WebConferenceGetQuote /> },
        { path: 'book/translator', element: <BookTranslator /> },
        { path: 'translator-payment/:id', element: <TranslatorPayment /> },
        { path: 'translator-order/:id', element: <TranslatorOrderNow /> },
        { path: 'translator-quote/:id', element: <TranslatorGetQuote /> },
      ],
    },

    // Main Routes
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'comingsoon', element: <ComingSoon />, index: true },
        { path: '404', element: <Page404 /> },
        // { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '/vendors/terms&conditions', element: <TermsAndConditions /> },
    { path: '/vendors/travel-policy', element: <TravelPolicy /> },
    { path: '/vendors/privacypolicy', element: <PrivacyPolicy /> },
    { path: '/files/:id/:fileType', element: <TranslatedFiles /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));

// LINGUIST AUTHENTICATION
const LinguistLogin = Loadable(lazy(() => import('../pages/auth/linguistAuth/Login')));
const LinguistRegister = Loadable(lazy(() => import('../pages/auth/linguistAuth/Register')));
const LinguistResetPassword = Loadable(lazy(() => import('../pages/auth/linguistAuth/ResetPassword')));
const LinguistNewPassword = Loadable(lazy(() => import('../pages/auth/linguistAuth/NewPassword')));

// Client AUTHENTICATION
const ClientLogin = Loadable(lazy(() => import('../pages/auth/clientAuth/Login')));
const ClientRegister = Loadable(lazy(() => import('../pages/auth/clientAuth/Register')));
const ClientResetPassword = Loadable(lazy(() => import('../pages/auth/clientAuth/ResetPassword')));
const ClientNewPassword = Loadable(lazy(() => import('../pages/auth/clientAuth/NewPassword')));

// DASHBOARD

// GENERAL
const AllGeneralProjectsList = Loadable(lazy(() => import('../pages/dashboard/AllGeneralProjectsList')));

const GeneralQuoteApp = Loadable(lazy(() => import('../pages/dashboard/GeneralQuoteApp')));
const GeneralProjectApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Vault = Loadable(lazy(() => import('../pages/dashboard/Vault')));
const VaultFolder = Loadable(lazy(() => import('../pages/dashboard/VaultFolder')));
const VaultFiles = Loadable(lazy(() => import('../pages/dashboard/VaultFiles')));
const CreatePost = Loadable(lazy(() => import('../pages/dashboard/CreatePost')));
const IssueCreate = Loadable(lazy(() => import('../pages/dashboard/IssueCreate')));
const IssueEdit = Loadable(lazy(() => import('../pages/dashboard/IssueEdit')));
const IssueList = Loadable(lazy(() => import('../pages/dashboard/IssueList')));
const IssueDetails = Loadable(lazy(() => import('../pages/dashboard/IssueDetails')));
const IssueReport = Loadable(lazy(() => import('../pages/dashboard/IssueReport')));
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));

// TASK
const TaskList = Loadable(lazy(() => import('../pages/dashboard/TaskList')));
const TaskCreate = Loadable(lazy(() => import('../pages/dashboard/TaskCreate')));
const TaskEdit = Loadable(lazy(() => import('../pages/dashboard/TaskEdit')));
const TasksDepartmentsList = Loadable(lazy(() => import('../pages/dashboard/TasksDepartmentsList')));
// LINGUIT
const LinguistTranslatorList = Loadable(lazy(() => import('../pages/dashboard/LinguistTranslatorList')));
const LinguistInterpreterList = Loadable(lazy(() => import('../pages/dashboard/LinguistInterpreterList')));
const LinguistIncompleteList = Loadable(lazy(() => import('../pages/dashboard/LinguistIncompleteList')));
const LinguistTechnicianList = Loadable(lazy(() => import('../pages/dashboard/LinguistTechnicianList')));
const LinguistBlackList = Loadable(lazy(() => import('../pages/dashboard/LinguistBlackList')));
const LinguistDetail = Loadable(lazy(() => import('../pages/dashboard/LinguistDetail')));
const LinguistCVDetail = Loadable(lazy(() => import('../pages/dashboard/LinguistCVDetails')));
const TechnicianView = Loadable(lazy(() => import('../pages/dashboard/TechnicianDetails')));
const VendorProfileOnBoarding = Loadable(lazy(() => import('../pages/linguist/ProfileOnBoarding')));
const VendorInvoices = Loadable(lazy(() => import('../pages/linguist/InvoiceList')));
const VendorInvoiceDetail = Loadable(lazy(() => import('../pages/linguist/InvoiceDetails')));
const VendorInvoiceCreate = Loadable(lazy(() => import('../pages/linguist/InvoiceCreate')));
// BARZANO PROJECT
const BarzanoDeliveryProjectList = Loadable(lazy(() => import('../pages/dashboard/BarzanoDeliveryProjectsList')));
const BarzanoProjectList = Loadable(lazy(() => import('../pages/dashboard/BarzanoProjectList')));
const BarzanoProjectCreate = Loadable(lazy(() => import('../pages/dashboard/BarzanoProjectCreate')));
const BarzanoProjectEdit = Loadable(lazy(() => import('../pages/dashboard/BarzanoProjectEdit')));
const BarzanoProjectDetails = Loadable(lazy(() => import('../pages/dashboard/BarzanoProjectDetails')));
const BarzanoProjectBooking = Loadable(lazy(() => import('../pages/dashboard/BarzanoProjectBooking')));

// TRANSLATION PROJECT
const TranslationProjectCreate = Loadable(lazy(() => import('../pages/dashboard/TranslationProjectCreate')));
const TranslationProjectList = Loadable(lazy(() => import('../pages/dashboard/TranslationProjectList')));
const TranslationProjectEdit = Loadable(lazy(() => import('../pages/dashboard/TranslationProjectEdit')));
const TranslationProjectDetails = Loadable(lazy(() => import('../pages/dashboard/TranslationProjectDetails')));
const TranslationProjectBooking = Loadable(lazy(() => import('../pages/dashboard/TranslationProjectBooking')));

//TRANSLATION QUOTE
const TranslationQuoteCreate = Loadable(lazy(() => import('../pages/dashboard/TranslationQuoteCreate')));
const TranslationQuoteList = Loadable(lazy(() => import('../pages/dashboard/TranslationQuoteList')));
const TranslationQuoteEdit = Loadable(lazy(() => import('../pages/dashboard/TranslationQuoteEdit')));
const TranslationQuoteDetails = Loadable(lazy(() => import('../pages/dashboard/TranslationQuoteDetails')));
// const TranslationQuotesReports = Loadable(lazy(() => import('../pages/dashboard/TranslationQuotesReports')));

// REPORTS
const TotalSalesFlowChartReport = Loadable(lazy(() => import('../pages/dashboard/TotalSalesFlowChartReport')));
const Management = Loadable(lazy(() => import('../pages/dashboard/Management')));
const QuotesConversionReports = Loadable(lazy(() => import('../pages/dashboard/QuotesConversionReports')));
const MarketingChannelQuotesList = Loadable(lazy(() => import('../pages/dashboard/MarketingChannelQuotesList')));

// COMPANY

const CompanyList = Loadable(lazy(() => import('../pages/dashboard/CompanyList')));
const CompanyCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyCreate')));
const CompanyEdit = Loadable(lazy(() => import('../pages/dashboard/CompanyEdit')));
const CompanyDetails = Loadable(lazy(() => import('../pages/dashboard/CompanyDetails')));

// INTERPRETATION PROJECT

const InterpretationProjectList = Loadable(lazy(() => import('../pages/dashboard/InterpretationProjectList')));
const InterpretationProjectCreate = Loadable(lazy(() => import('../pages/dashboard/InterpretationProjectCreate')));
const InterpretationProjectEdit = Loadable(lazy(() => import('../pages/dashboard/InterpretationProjectEdit')));
const InterpretationProjectDetails = Loadable(lazy(() => import('../pages/dashboard/InterpretationProjectDetails')));
const InterpretationProjectBooking = Loadable(lazy(() => import('../pages/dashboard/InterpretationProjectBooking')));

//INTERPRETATION QUOTES

const InterpretationQuoteList = Loadable(lazy(() => import('../pages/dashboard/InterpretationQuoteList')));
const InterpretationQuoteCreate = Loadable(lazy(() => import('../pages/dashboard/InterpretationQuoteCreate')));
const InterpretationQuoteEdit = Loadable(lazy(() => import('../pages/dashboard/InterpretationQuoteEdit')));
const InterpretationQuoteDetails = Loadable(lazy(() => import('../pages/dashboard/InterpretationQuoteDetails')));
// const InterpretationQuotesReports = Loadable(lazy(() => import('../pages/dashboard/InterpretationQuotesReport')));

//Brochure
const BrochureList = Loadable(lazy(() => import('../pages/dashboard/BrochureList')));
const BrochureCreate = Loadable(lazy(() => import('../pages/dashboard/BrochureCreate')));
const BrochureEdit = Loadable(lazy(() => import('../pages/dashboard/BrochureEdit')));
const BrochureDetails = Loadable(lazy(() => import('../pages/dashboard/BrochureDetails')));

//Expense
const ExpenseList = Loadable(lazy(() => import('../pages/dashboard/ExpenseList')));
const ExpenseCreate = Loadable(lazy(() => import('../pages/dashboard/ExpenseCreate')));
const ExpenseEdit = Loadable(lazy(() => import('../pages/dashboard/ExpenseEdit')));
const ExpenseDetails = Loadable(lazy(() => import('../pages/dashboard/ExpenseDetails')));
const CreditNoteCreate = Loadable(lazy(() => import('../pages/dashboard/CreditNoteCreate')));
const CreditNoteEdit = Loadable(lazy(() => import('../pages/dashboard/CreditNoteEdit')));
const CreditNoteDetails = Loadable(lazy(() => import('../pages/dashboard/CreditNoteDetails')));

// VIDEO CONFERENCE PROJECT

const VideoConferenceProjectList = Loadable(lazy(() => import('../pages/dashboard/VideoConferenceProjectList')));
const VideoConferenceProjectCreate = Loadable(lazy(() => import('../pages/dashboard/VideoConferenceProjectCreate')));
const VideoConferenceProjectEdit = Loadable(lazy(() => import('../pages/dashboard/VideoConferenceProjectEdit')));
const VideoConferenceProjectDetails = Loadable(lazy(() => import('../pages/dashboard/VideoConferenceProjectDetails')));

// REPORT
const AMReport = Loadable(lazy(() => import('../pages/dashboard/Reports')));
// LINGUIST ALLOCATION

const BarzanoAllocationResponse = Loadable(
  lazy(() => import('../pages/linguist/linguistAllocation/BarzanoAllocationResponse'))
);
const TranslationAllocationResponse = Loadable(
  lazy(() => import('../pages/linguist/linguistAllocation/TranslationAllocationResponse'))
);
const TranslationPRAllocationResponse = Loadable(
  lazy(() => import('../pages/linguist/linguistAllocation/TranslationPRAllocationResponse'))
);
const InterpretationAllocationResponse = Loadable(
  lazy(() => import('../pages/linguist/linguistAllocation/InterpretationAllocationResponse'))
);
// LINGUIST
const TermsAndConditions = Loadable(lazy(() => import('../pages/linguist/TermsAndConditions')));
const TravelPolicy = Loadable(lazy(() => import('../pages/linguist/TravelPolicy')));
const TranslationProjects = Loadable(lazy(() => import('../pages/linguist/TranslationProjects')));
const InterpretationProjects = Loadable(lazy(() => import('../pages/linguist/InterpretationProjects')));
const PRList = Loadable(lazy(() => import('../pages/linguist/PRList')));
const ProjectView = Loadable(lazy(() => import('../pages/linguist/ProjectView')));
const PRView = Loadable(lazy(() => import('../pages/linguist/PRView')));
const VerifyEmail = Loadable(lazy(() => import('../pages/linguist/VerifyEmail')));
const UpdatePassword = Loadable(lazy(() => import('../pages/linguist/UpdatePassword')));
const LinguistDashboard = Loadable(lazy(() => import('../pages/linguist/Dashboard')));
const LinguistProfile = Loadable(lazy(() => import('../pages/linguist/ViewProfile')));
const LinguistProjects = Loadable(lazy(() => import('../pages/linguist/Projects')));
const LinguistPaymentInfo = Loadable(lazy(() => import('../pages/linguist/PaymentInfo')));
// const Services = Loadable(lazy(() => import('../pages/linguist/Services')));
const Services = Loadable(lazy(() => import('../pages/linguist/Services')));
const TechnicianSheets = Loadable(lazy(() => import('../pages/technician/TechnicianSheetList')));
const TechnicianDetails = Loadable(lazy(() => import('../pages/technician/TechnicianDetails')));
const MarketingChannelList = Loadable(lazy(() => import('../pages/marketeer/MarketingChannelList')));
const FormattingFilesList = Loadable(lazy(() => import('../pages/formatter/FormattingFilesList')));
const FormattingFileDetails = Loadable(lazy(() => import('../pages/formatter/FormattingFileDetails')));
const ProjectsCalendar = Loadable(lazy(() => import('../pages/formatter/ProjectsCalendar')));
const ExpenseReport = Loadable(lazy(() => import('../pages/dashboard/ExpenseReport')));
const BarzanoInvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/ClientInvoiceCreate')));
const LYCIClientInvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/LYCIClientInvoiceCreate')));
const BarzanoInvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/ClientInvoiceEdit')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
// const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')))
const BarzanoInvoiceView = Loadable(lazy(() => import('../pages/dashboard/ClientInvoiceDetails')));
const BarzanoInvoiceList = Loadable(lazy(() => import('../pages/dashboard/ClientInvoiceList')));
const TechnicianInvoice = Loadable(lazy(() => import('../pages/technician/InvoiceList')));
const TechnicianInvoiceDetail = Loadable(lazy(() => import('../pages/technician/InvoiceDetails')));
const TechnicianInvoiceCreate = Loadable(lazy(() => import('../pages/technician/InvoiceCreate')));
const TechnicianInventoryDetails = Loadable(lazy(() => import('../pages/technician/InventoryDetails')));
const TechnicianInventoryList = Loadable(lazy(() => import('../pages/technician/InventoryList')));
const TechnicianInventoryCreate = Loadable(lazy(() => import('../pages/technician/InventoryCreate')));
const TechnicianInventoryEdit = Loadable(lazy(() => import('../pages/technician/InventoryEdit')));
// USER
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/UserEdit')));

// Inventory

const InventoryList = Loadable(lazy(() => import('../pages/dashboard/InventoryList')));
const InventoryCreate = Loadable(lazy(() => import('../pages/dashboard/InventoryCreate')));
const InventoryEdit = Loadable(lazy(() => import('../pages/dashboard/InventoryEdit')));
const InventoryDetails = Loadable(lazy(() => import('../pages/dashboard/InventoryDetails')));
const InventoryOverview = Loadable(lazy(() => import('../pages/dashboard/InventoryOverview')));

// TEST RENDER PAGE BY Type
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

//OCR

const OcrReport = Loadable(lazy(() => import('../pages/dashboard/Ocr')));

//TextToSpeech

// const TextToSpeech = Loadable(lazy(() => import('../pages/dashboard/TextToSpeech')));

const TwilioCall = Loadable(lazy(() => import('../pages/dashboard/TwilioCall')));
const CallRecordsList = Loadable(lazy(() => import('../pages/dashboard/CallRecordsList')));

// Tickets
const TicketSupport = Loadable(lazy(() => import('../pages/dashboard/TicketSupport')));
const TicketCreate = Loadable(lazy(() => import('../pages/dashboard/TicketCreate')));
const TicketEdit = Loadable(lazy(() => import('../pages/dashboard/TicketEdit')));
const TicketList = Loadable(lazy(() => import('../pages/dashboard/TicketList')));
const TicketKanban = Loadable(lazy(() => import('../pages/dashboard/TicketKanban')));
const TicketDetails = Loadable(lazy(() => import('../pages/dashboard/TicketDetails')));

// MAIN

const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const TranslatedFiles = Loadable(lazy(() => import('../pages/TranslatedFiles')));

// Client
const Dashboard = Loadable(lazy(() => import('../pages/client/Dashboard')));
// const BarzanoCalendar = Loadable(lazy(() => import('../pages/client/BarzanoCalendar')));
const EditClient = Loadable(lazy(() => import('../pages/client/EditProfile')));
const InstantQuote = Loadable(lazy(() => import('../pages/client/InstantQuote')));
const OrderList = Loadable(lazy(() => import('../pages/client/OrderList')));
const OrderDetails = Loadable(lazy(() => import('../pages/client/OrderDetails')));
const BookInterpreter = Loadable(lazy(() => import('../pages/client/book/BookInterpreter')));
const InterpreterPayment = Loadable(lazy(() => import('../pages/client/bookInterpreter/Payment')));
const InterpreterOrderNow = Loadable(lazy(() => import('../pages/client/bookInterpreter/OrderNow')));
const InterpreterGetQuote = Loadable(lazy(() => import('../pages/client/bookInterpreter/GetQuote')));
const BookWebConference = Loadable(lazy(() => import('../pages/client/book/BookWebConference')));
const WebConferencePayment = Loadable(lazy(() => import('../pages/client/bookWebConference/Payment')));
const WebConferenceOrderNow = Loadable(lazy(() => import('../pages/client/bookWebConference/OrderNow')));
const WebConferenceGetQuote = Loadable(lazy(() => import('../pages/client/bookWebConference/GetQuote')));
const BookTranslator = Loadable(lazy(() => import('../pages/client/book/BookTranslator')));
const TranslatorPayment = Loadable(lazy(() => import('../pages/client/bookTranslator/Payment')));
const TranslatorOrderNow = Loadable(lazy(() => import('../pages/client/bookTranslator/OrderNow')));
const TranslatorGetQuote = Loadable(lazy(() => import('../pages/client/bookTranslator/GetQuote')));
